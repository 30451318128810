// background shade
@mixin bg-overlay($color: black, $percent: 0.2) {
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 3;
	background-color: rgba($color, $percent); }

// background image defaults
@mixin bg-img() {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat; }

