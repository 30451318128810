// mobile navigation
#menu {
	position: fixed;
	right: -100%;
	top: 0;
	z-index: 900;
	width: 100%;
	min-height: 100vh;
	transition: $base-transition;
	background-color: $brand-color-1;
	color: white;
	nav {
		margin: 0;
		min-height: 100vh;
		padding: $base-spacing;
		position: relative;
		background: $brand-color-1;
		text-align: center;
		@include flex-container($direction: column);
		a.logo {
			margin: 0 auto;
			display: block;
			position: relative;
			> * {
				height: 180px; } }
		ul {
			font-family: $heading-font-family;
			font-size: em(20);
			li {
				margin-bottom: $base-spacing;
				display: block;
				a {
					color: white;
					padding: $tiny-spacing $small-spacing;
					&:hover, &.active {
						color: rgba(white,0.5); }
					&.active {
						font-weight: $bold-font-weight; } }
				&:last-child a {
					background-color: white;
					color: $brand-color-1;
					&:hover {
						color: tint($brand-color-1, 30%); } } } }
		p {
			font-size: em(14);
			margin: 0; } } }

// active navigation
body.nav-active {
	#wrapper {
		overflow: hidden;
		max-height: 100%; }
	#menu {
		right: 0;
		nav {
			min-width: 100%; } } }

// default navigation
#wrapper > header {
	nav {
		$top-border-width: 6px;
		border-top: $top-border-width solid $brand-color-1;
		top: calc(#{$base-spacing} + 36px);
		position: relative;
		ul {
			position: relative;
			text-align: right;
			display: none;
			padding-right: $large-spacing;
			li {
				display: inline-block;
				border-top: $top-border-width solid $brand-color-2;
				margin-top: -$top-border-width;
				margin-right: -4px;
				font-family: $heading-font-family;
				&:nth-child(2) {
					border-color: $brand-color-3; }
				&:nth-child(3) {
					border-color: $brand-color-4; }
				a {
					padding: $tiny-spacing $base-spacing;
					color: $base-color;
					display: inline-block;
					&:hover, &.active {
						color: $brand-color-1; } } } }

		#menuTrigger {
			$bar-height: 4px;
			background-color: white;
			position: absolute;
			right: $small-spacing;
			top: -24px;
			padding: 10px;
			z-index: 1000;
			a {
				width: 36px;
				height: 28px;
				transform: rotate(0deg);
				transition: .125s $base-timing;
				cursor: pointer;
				display: block;
				position: relative;
				span {
					display: block;
					position: absolute;
					left: 0;
					height: $bar-height;
					width: 100%;
					background: $brand-color-2;
					opacity: 1;
					transform: rotate(0deg);
					transition: .125s $base-timing;
					&:nth-child(1) {
						top: 0px; }
					&:nth-child(2), &:nth-child(3) {
						top: $bar-height*3; // height *2
						background: $brand-color-3; }
					&:nth-child(4) {
						top: $bar-height*6; // height *4
						background: $brand-color-4; } } }
			&.open {
				background-color: transparent;
				a span {
					&:nth-child(1) {
						top: $bar-height*2; // height *2
						width: 0%;
						left: 50%; }
					&:nth-child(2), &:nth-child(3) {
						background: white; }
					&:nth-child(2) {
						transform: rotate(45deg); }
					&:nth-child(3) {
						transform: rotate(-45deg); }
					&:nth-child(4) {
						top: $bar-height*2; // height *2
						width: 0%;
						left: 50%; } } } } } }

#wrapper > footer {
	ul#social {
		text-align: center;
		display: block;
		@include flex-container($flex-items: 1 1 auto); } }


#pagination {
	@include flex-container;
	border-top: $base-border;
	padding-top: $base-spacing;
	> * {
		font-size: em(40); }

	.disabled {
		color: $base-background-color;
		cursor: default; } }


@media screen and (min-width: $medium-screen) {
	#wrapper > header {
		> nav {
			ul {
				display: block; }
			#menuTrigger {
				display: none; } } }
	#wrapper > footer {
		ul#social {
			text-align: left;
			@include flex-container($align-items: center); } } }

@media screen and (min-width: $x-large-screen) {
	#wrapper > header {
		nav {
			ul {
				padding-right: $x-large-spacing; } }


		// ul
		// 	flex: 1 0 auto
		// 	@include flex-container($align-items: center, $justify-content: flex-end)
		// 	text-align: right
		// 	font-family: $heading-font-family
		// 	font-weight: $bold-font-weight
		// 	font-size: em(24)
		// 	list-style-type: none
		// 	li
		// 		a
		// 			padding: $tiny-spacing $small-spacing
		// 		&.contact a
		// 			&:hover
		// 				background-color: $brand-color-2
		// 			margin-left: $tiny-spacing
		// 		&.active a
		// 			color: tint($action-color, 60%)
 } }		// 			cursor: default


