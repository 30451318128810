figure {
	margin: 0; }

img,
picture,
svg {
	margin: 0;
	max-width: 100%;
	max-height: 100%;
	height: auto; }

iframe {
	display: block;
	width: 100%; }

figcaption {
	padding: $small-spacing;
	border-bottom: $base-border;
	color: $base-color-medium;
	width: 100%;
	font-size: .9em;
	font-style: italic;
	text-align: center; }

// paper audio player mods
paper-audio-player {
	margin: $base-spacing 0;
	&.paper-audio-player-0 {
		#wrapper.paper-audio-player {
			box-shadow: none;
			border: $base-border;
			height: 52px; }
		#left.paper-audio-player, #progress.paper-audio-player {
			background-color: $brand-color-4; }
		#title.paper-audio-player, #replay.paper-audio-player, #duration.paper-audio-player, paper-ripple.paper-audio-player {
			color: $brand-color-4; } } }
