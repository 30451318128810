// page styles
.bio {
	@include clearfix;
	h2 {
		margin-bottom: 0; }
	.info {
		font-family: $google-sans;
		color: $brand-color-1;
		font-size: em(20);
		margin-bottom: $base-spacing; } }
