// Convert pixels to ems
// eg. for a relational value of 12px write em(12) when the parent is 16px
// if the parent is another value say 24px write em(12, 24)

// after upgrading to bourbon 5.0, use this function as bourbon's will be deprecated
$browser-context: 16;

@function em($pixels, $context: $browser-context) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px; }

	@if (unitless($context)) {
		$context: $context * 1px; }


	@return $pixels / $context * 1em; }
