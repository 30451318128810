// Material Icon rules
// Rules for sizing the icon.
.material-icons.md-18 {
	font-size: em(18); }
.material-icons.md-24 {
	font-size: em(24); }
.material-icons.md-36 {
	font-size: em(36); }
.material-icons.md-48 {
	font-size: em(48); }

// Rules for using icons as black on a light background.
.material-icons.md-dark {
	color: rgba(black, 0.54); }
.material-icons.md-dark.md-inactive {
	color: rgba(black, 0.26); }

// Rules for using icons as white on a dark background.
.material-icons.md-light {
	color: rgba(white, 1); }
.material-icons.md-light.md-inactive {
	color: rgba(white, 0.3); }

body {
	color: $base-font-color;
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-line-height;
	font-weight: $base-font-weight; }

@include headings() {
	font-family: $heading-font-family;
	// font-size: modular-scale(1)
	line-height: $heading-line-height;
	margin: 0 0 $tiny-spacing;
	font-weight: 400;
	&:first-child {
		margin-top: 0; } }

h1 {
	font-size: $h1-font-size;
	margin-top: calc(#{$h1-font-size} / 2); }
h2 {
	font-size: $h2-font-size;
	margin-top: calc(#{$h2-font-size} / 2); }
h3 {
	font-size: $h3-font-size;
	margin-top: calc(#{$h3-font-size} / 2); }
h4 {
	font-size: $h4-font-size;
	margin-top: calc(#{$h4-font-size} / 2); }
h5 {
	font-size: $h5-font-size;
	text-transform: uppercase;
	color: $base-color-medium;
	margin-top: calc(#{$h5-font-size} / 2); }
h6 {
	font-size: $h6-font-size;
	text-transform: uppercase;
	margin-top: calc(#{$h6-font-size} / 2); }

p {
	margin: 0 0 $small-spacing;
	font-size: em(15);
	&:last-child {
		margin-bottom: 0; }
	&.intro {
		color: $brand-color-1;
		font-size: em(20);
		font-family: $google-sans;
		margin: $large-spacing 0 $base-spacing 0; }
	a {
		text-decoration: underline; } }


a {
	color: $action-color;
	text-decoration: none;
	transition: color $base-duration $base-timing;

	&:active,
	&:focus,
	&:hover {
		color: $base-color; } }

hr {
	border-bottom: $base-border;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	margin: $base-spacing 0; }

strong {
	font-weight: $bold-font-weight; }

em {
	font-style: italic; }

sub, sup {
	font-size: 75%;
	padding-left: 0.2em;
	line-height: 0;
	position: relative; }

sup {
	top: -0.5em; }

sub {
	bottom: -0.25em; }

pre {
	font-family: $mono-font-family;
	white-space: pre-wrap;
	padding: $base-spacing;
	border: 1px solid rgba($brand-color-1, 0.5);
	background-color: tint($brand-color-1, 92%);
	margin: $large-spacing 0;
	code {
		overflow-wrap: break-word; } }

blockquote {
	font-size: em(32);
	color: $brand-color-1;
	font-weight: $bold-font-weight;
	text-align: right;
	font-family: $heading-font-family;
	margin: $large-spacing $base-spacing;
	> * {
		text-align: center; }

	cite {
		font-family: $base-font-family;
		font-style: normal;
		font-weight: normal;
		display: inline-block;
		font-size: em(16, 32);
		border-top: $base-border;
		text-align: right;
		color: $base-color-light;
		padding: $tiny-spacing $base-spacing; } }


