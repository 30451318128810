html {
	box-sizing: border-box; }

*,
*::before,
*::after {
	box-sizing: inherit; }

html,
body {
	height: 100%; }

body > #wrapper {
	> header {
		position: relative;
		@include outer-container;
		min-height: calc(#{$base-spacing} + 160px); }
	.logo {
		height: 160px;
		display: block;
		position: absolute;
		top: $small-spacing;
		left: $base-spacing;
		z-index: 800;
		> * {
			width: auto;
			height: 100%; } }

	> #pageContent, > footer {
		padding: $base-spacing; }

	> #pageContent {
		.columns {
			margin: $base-spacing 0;
			> *:not(:last-child) {
				margin-bottom: $base-spacing; } } }


	> footer {
		border-top: 2px solid $base-color-light;
		text-align: center;
		margin-top: $base-spacing;
		> div {
			font-size: em(14);
			color: $base-color-medium;
			margin-top: $base-spacing;
			p:first-child {
				margin-bottom: $tiny-spacing; }
			a {
				color: $base-color-medium; } } }


	@media screen and (min-width: $small-screen) {
		> #pageContent {
			.columns {
				@include flex-container;
				margin: $large-spacing 0;
				> * {
					flex-basis: 50%;
					margin: 0 !important;
					&:nth-child(odd) {
						padding-right: $base-spacing; }
					&:nth-child(even) {
						padding-left: $base-spacing; } } } } }


	@media screen and (min-width: $medium-screen) {
		> header {
			.logo {
				left: $large-spacing; } }
		> #pageContent, > footer {
			padding-right: $large-spacing;
			padding-left: 22%; }

		> footer {
			@include flex-container($flex-items: 1 1 25%);
			> div {
				text-align: right;
				margin-top: 0;
				flex: 0 1 70%; } } }

	@media screen and (min-width: $large-screen) {
		> header {
			.logo {
				position: fixed;
				left: 10%; } }
		> #pageContent, > footer {
			padding-right: $large-spacing;
			padding-left: 30%; } }




	@media screen and (min-width: $x-large-screen) {
		> header {
			.logo {
				left: 12%; } }

		> #pageContent, > footer {
			padding-right: $x-large-spacing;
			padding-left: 35%; } } }

