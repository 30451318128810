* {
  box-sizing: border-box; }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, code, img, strike, strong, sub, sup,
fieldset, figcaption, figure, form, label, q,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, figure, figcaption, footer, header,
menu, nav, section, summary, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  outline: none; }

body {
  -webkit-text-size-adjust: none; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

a {
  text-decoration: none;
  color: inherit; }
  a img {
    border: 0; }

main, figcaption, figure {
  display: block; }

table, th, tbody, tr, td {
  border: 0; }

[type=text], [type=email], [type=number], [type=password], textarea {
  padding: 0;
  border: none;
  background: none;
  font: inherit;
  letter-spacing: inherit; }
  [type=text]:focus, [type=email]:focus, [type=number]:focus, [type=password]:focus, textarea:focus {
    border-color: transparent;
    outline: none; }

button, input[type=submit] {
  font: inherit; }
  button:focus, input[type=submit]:focus {
    outline: none; }

@font-face {
  font-family: "GoogleSans";
  font-style: normal;
  font-weight: normal;
  src: url("/_assets/styles/fonts/google-sans-webfont/googlesans-regular-webfont.eot");
  src: url("/_assets/styles/fonts/google-sans-webfont/googlesans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/_assets/styles/fonts/google-sans-webfont/googlesans-regular-webfont.woff") format("woff"), url("/_assets/styles/fonts/google-sans-webfont/googlesans-regular-webfont.ttf") format("truetype"), url("/_assets/styles/fonts/google-sans-webfont/googlesans-regular-webfont.svg#GoogleSans") format("svg"); }

@font-face {
  font-family: "GoogleSansBold";
  font-style: "bold";
  font-weight: normal;
  src: url("/_assets/styles/fonts/google-sans-webfont/googlesans-bold-webfont.eot");
  src: url("/_assets/styles/fonts/google-sans-webfont/googlesans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/_assets/styles/fonts/google-sans-webfont/googlesans-bold-webfont.woff") format("woff"), url("/_assets/styles/fonts/google-sans-webfont/googlesans-bold-webfont.ttf") format("truetype"), url("/_assets/styles/fonts/google-sans-webfont/googlesans-bold-webfont.svg#GoogleSansBold") format("svg"); }

button, [type='button'], [type='reset'], [type='submit'], .button {
  appearance: none;
  background-color: #4285f4;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "GoogleSansBold", "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 1.25em;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  line-height: 1;
  padding: 0.5em 0.75em;
  text-align: center;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover, [type='button']:hover, [type='reset']:hover, [type='submit']:hover, .button:hover {
    background-color: #b7b7b7;
    color: white; }
  button:focus, [type='button']:focus, [type='reset']:focus, [type='submit']:focus, .button:focus {
    background-color: #757575; }
  button:disabled, [type='button']:disabled, [type='reset']:disabled, [type='submit']:disabled, .button:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    button:disabled:hover, [type='button']:disabled:hover, [type='reset']:disabled:hover, [type='submit']:disabled:hover, .button:disabled:hover {
      background-color: #4285f4; }

form {
  padding: 1.5em 0;
  margin-bottom: 1.5em; }

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0; }
  fieldset .fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch; }
    fieldset .fields > * {
      flex: 0 1 auto; }
    fieldset .fields .input {
      flex-basis: 100%; }
      fieldset .fields .input label {
        display: none; }

legend {
  font-weight: 700;
  margin-bottom: 0.375em;
  padding: 0;
  font-family: "GoogleSansBold", "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 1.75em;
  color: #4285f4; }

label {
  display: block;
  font-weight: 700;
  margin-bottom: 0.375em; }

input,
select,
textarea {
  display: block;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 1em; }

[type='color'], [type='date'], [type='datetime'], [type='datetime-local'], [type='email'], [type='month'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='time'], [type='url'], [type='week'], input:not([type]), textarea {
  appearance: none;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
  border-color: transparent;
  box-sizing: border-box;
  margin-bottom: 1.5em;
  padding: 0.75em;
  transition: all 150ms ease;
  width: 100%; }
  [type='color']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='email']:focus, [type='month']:focus, [type='number']:focus, [type='password']:focus, [type='search']:focus, [type='tel']:focus, [type='text']:focus, [type='time']:focus, [type='url']:focus, [type='week']:focus, input:not([type]):focus, textarea:focus {
    border-color: #4285f4;
    background-color: #f3f3f3;
    outline: none; }
  [type='color']:disabled, [type='date']:disabled, [type='datetime']:disabled, [type='datetime-local']:disabled, [type='email']:disabled, [type='month']:disabled, [type='number']:disabled, [type='password']:disabled, [type='search']:disabled, [type='tel']:disabled, [type='text']:disabled, [type='time']:disabled, [type='url']:disabled, [type='week']:disabled, input:not([type]):disabled, textarea:disabled {
    background-color: #dbdbdb;
    cursor: not-allowed; }
    [type='color']:disabled:hover, [type='date']:disabled:hover, [type='datetime']:disabled:hover, [type='datetime-local']:disabled:hover, [type='email']:disabled:hover, [type='month']:disabled:hover, [type='number']:disabled:hover, [type='password']:disabled:hover, [type='search']:disabled:hover, [type='tel']:disabled:hover, [type='text']:disabled:hover, [type='time']:disabled:hover, [type='url']:disabled:hover, [type='week']:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover {
      border: 1px solid #e6e6e6; }
  [type='color']::placeholder, [type='date']::placeholder, [type='datetime']::placeholder, [type='datetime-local']::placeholder, [type='email']::placeholder, [type='month']::placeholder, [type='number']::placeholder, [type='password']::placeholder, [type='search']::placeholder, [type='tel']::placeholder, [type='text']::placeholder, [type='time']::placeholder, [type='url']::placeholder, [type='week']::placeholder, input:not([type])::placeholder, textarea::placeholder {
    color: #7a7a7a; }

textarea {
  resize: vertical; }

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

[type="file"] {
  margin-bottom: 0.75em;
  width: 100%; }

select {
  margin-bottom: 0.75em;
  width: 100%; }

input[type="submit"] {
  font-family: "GoogleSansBold", "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-weight: 700;
  font-size: 1.25em; }

@media screen and (min-width: 480px) {
  fieldset .fields .input {
    flex-basis: 50%; }
    fieldset .fields .input:nth-child(odd) {
      padding-right: 0.75em; }
    fieldset .fields .input:nth-child(even) {
      padding-left: 0.75em; }
    fieldset .fields .input.full {
      padding: 0;
      flex-basis: 100%; } }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

html,
body {
  height: 100%; }

body > #wrapper > header {
  position: relative;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(1.5em + 160px); }
  body > #wrapper > header::after {
    clear: both;
    content: "";
    display: block; }

body > #wrapper .logo {
  height: 160px;
  display: block;
  position: absolute;
  top: 0.75em;
  left: 1.5em;
  z-index: 800; }
  body > #wrapper .logo > * {
    width: auto;
    height: 100%; }

body > #wrapper > #pageContent, body > #wrapper > footer {
  padding: 1.5em; }

body > #wrapper > #pageContent .columns {
  margin: 1.5em 0; }
  body > #wrapper > #pageContent .columns > *:not(:last-child) {
    margin-bottom: 1.5em; }

body > #wrapper > footer {
  border-top: 2px solid #b7b7b7;
  text-align: center;
  margin-top: 1.5em; }
  body > #wrapper > footer > div {
    font-size: 0.875em;
    color: #757575;
    margin-top: 1.5em; }
    body > #wrapper > footer > div p:first-child {
      margin-bottom: 0.5em; }
    body > #wrapper > footer > div a {
      color: #757575; }

@media screen and (min-width: 480px) {
  body > #wrapper > #pageContent .columns {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    margin: 3em 0; }
    body > #wrapper > #pageContent .columns > * {
      flex: 0 1 auto; }
    body > #wrapper > #pageContent .columns > * {
      flex-basis: 50%;
      margin: 0 !important; }
      body > #wrapper > #pageContent .columns > *:nth-child(odd) {
        padding-right: 1.5em; }
      body > #wrapper > #pageContent .columns > *:nth-child(even) {
        padding-left: 1.5em; } }

@media screen and (min-width: 768px) {
  body > #wrapper > header .logo {
    left: 3em; }
  body > #wrapper > #pageContent, body > #wrapper > footer {
    padding-right: 3em;
    padding-left: 22%; }
  body > #wrapper > footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch; }
    body > #wrapper > footer > * {
      flex: 1 1 25%; }
    body > #wrapper > footer > div {
      text-align: right;
      margin-top: 0;
      flex: 0 1 70%; } }

@media screen and (min-width: 1200px) {
  body > #wrapper > header .logo {
    position: fixed;
    left: 10%; }
  body > #wrapper > #pageContent, body > #wrapper > footer {
    padding-right: 3em;
    padding-left: 30%; } }

@media screen and (min-width: 1600px) {
  body > #wrapper > header .logo {
    left: 12%; }
  body > #wrapper > #pageContent, body > #wrapper > footer {
    padding-right: 9em;
    padding-left: 35%; } }

ul, ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

dl {
  margin: 0; }

dt {
  font-weight: 700;
  margin: 0; }

dd {
  margin: 0; }

#pageContent ul, #pageContent ol {
  overflow: hidden; }
  #pageContent ul li, #pageContent ol li {
    margin-bottom: 0.5em; }
    #pageContent ul li ul, #pageContent ul li ol, #pageContent ol li ul, #pageContent ol li ol {
      margin-top: 0.25em; }
    #pageContent ul li li, #pageContent ol li li {
      margin-bottom: 0.25em; }

#pageContent ul {
  list-style-type: none;
  padding-left: 1.5em; }
  #pageContent ul li {
    position: relative; }
    #pageContent ul li:before {
      width: 3em;
      margin-left: -3em;
      top: -0.25em;
      position: relative;
      text-align: center;
      content: "\f111";
      font-family: FontAwesome;
      font-size: 0.375em;
      color: #4285f4;
      padding-right: 0.75em;
      display: inline-block; }

#pageContent ol {
  list-style-type: none;
  padding-left: 3em;
  counter-reset: li; }
  #pageContent ol li {
    counter-increment: li; }
    #pageContent ol li:before {
      content: counter(li);
      color: #4285f4;
      display: inline-block;
      width: 3em;
      margin-left: -3em;
      position: relative;
      text-align: right;
      padding-right: 0.5em; }
  #pageContent ol ol {
    padding-left: 1.5em; }
    #pageContent ol ol li:before {
      content: counter(li, lower-alpha); }

figure {
  margin: 0; }

img,
picture,
svg {
  margin: 0;
  max-width: 100%;
  max-height: 100%;
  height: auto; }

iframe {
  display: block;
  width: 100%; }

figcaption {
  padding: 0.75em;
  border-bottom: 1px solid #e6e6e6;
  color: #757575;
  width: 100%;
  font-size: .9em;
  font-style: italic;
  text-align: center; }

paper-audio-player {
  margin: 1.5em 0; }
  paper-audio-player.paper-audio-player-0 #wrapper.paper-audio-player {
    box-shadow: none;
    border: 1px solid #e6e6e6;
    height: 52px; }
  paper-audio-player.paper-audio-player-0 #left.paper-audio-player, paper-audio-player.paper-audio-player-0 #progress.paper-audio-player {
    background-color: #34a853; }
  paper-audio-player.paper-audio-player-0 #title.paper-audio-player, paper-audio-player.paper-audio-player-0 #replay.paper-audio-player, paper-audio-player.paper-audio-player-0 #duration.paper-audio-player, paper-audio-player.paper-audio-player-0 paper-ripple.paper-audio-player {
    color: #34a853; }

table {
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #adadad;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #e6e6e6;
  padding: 0.75em 0; }

tr, td, th {
  vertical-align: middle; }

.material-icons.md-18 {
  font-size: 1.125em; }

.material-icons.md-24 {
  font-size: 1.5em; }

.material-icons.md-36 {
  font-size: 2.25em; }

.material-icons.md-48 {
  font-size: 3em; }

.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54); }

.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26); }

.material-icons.md-light {
  color: white; }

.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3); }

body {
  color: #212121;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 1em;
  line-height: 1.5;
  font-weight: 400; }

h1 {
  font-family: "GoogleSansBold", "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  line-height: 1.2;
  margin: 0 0 0.5em;
  font-weight: 400; }
  h1:first-child {
    margin-top: 0; }

h2 {
  font-family: "GoogleSansBold", "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  line-height: 1.2;
  margin: 0 0 0.5em;
  font-weight: 400; }
  h2:first-child {
    margin-top: 0; }

h3 {
  font-family: "GoogleSansBold", "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  line-height: 1.2;
  margin: 0 0 0.5em;
  font-weight: 400; }
  h3:first-child {
    margin-top: 0; }

h4 {
  font-family: "GoogleSansBold", "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  line-height: 1.2;
  margin: 0 0 0.5em;
  font-weight: 400; }
  h4:first-child {
    margin-top: 0; }

h5 {
  font-family: "GoogleSansBold", "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  line-height: 1.2;
  margin: 0 0 0.5em;
  font-weight: 400; }
  h5:first-child {
    margin-top: 0; }

h6 {
  font-family: "GoogleSansBold", "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  line-height: 1.2;
  margin: 0 0 0.5em;
  font-weight: 400; }
  h6:first-child {
    margin-top: 0; }

h1 {
  font-size: 3.375em;
  margin-top: calc(3.375em / 2); }

h2 {
  font-size: 2.625em;
  margin-top: calc(2.625em / 2); }

h3 {
  font-size: 2em;
  margin-top: calc(2em / 2); }

h4 {
  font-size: 1.75em;
  margin-top: calc(1.75em / 2); }

h5 {
  font-size: 1.5em;
  text-transform: uppercase;
  color: #757575;
  margin-top: calc(1.5em / 2); }

h6 {
  font-size: 1.125em;
  text-transform: uppercase;
  margin-top: calc(1.125em / 2); }

p {
  margin: 0 0 0.75em;
  font-size: 0.9375em; }
  p:last-child {
    margin-bottom: 0; }
  p.intro {
    color: #4285f4;
    font-size: 1.25em;
    font-family: "GoogleSans", "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
    margin: 3em 0 1.5em 0; }
  p a {
    text-decoration: underline; }

a {
  color: #4285f4;
  text-decoration: none;
  transition: color 150ms ease; }
  a:active, a:focus, a:hover {
    color: #212121; }

hr {
  border-bottom: 1px solid #e6e6e6;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0; }

strong {
  font-weight: 700; }

em {
  font-style: italic; }

sub, sup {
  font-size: 75%;
  padding-left: 0.2em;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

pre {
  font-family: "Courier New", "Courier", "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
  white-space: pre-wrap;
  padding: 1.5em;
  border: 1px solid rgba(66, 133, 244, 0.5);
  background-color: #f0f5fe;
  margin: 3em 0; }
  pre code {
    overflow-wrap: break-word; }

blockquote {
  font-size: 2em;
  color: #4285f4;
  font-weight: 700;
  text-align: right;
  font-family: "GoogleSansBold", "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  margin: 3em 1.5em; }
  blockquote > * {
    text-align: center; }
  blockquote cite {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    font-size: 0.5em;
    border-top: 1px solid #e6e6e6;
    text-align: right;
    color: #b7b7b7;
    padding: 0.5em 1.5em; }

.block {
  position: relative;
  margin: 1.5em auto; }
  .block * {
    position: relative; }
  .block.wrap, .block > .wrap {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }
    .block.wrap::after, .block > .wrap::after {
      clear: both;
      content: "";
      display: block; }
  .block.full {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }
    .block.full::after {
      clear: both;
      content: "";
      display: block; }
  .block.img.bg {
    background-size: cover;
    background-position: center center;
    min-height: 70vh; }
  .block.layout {
    display: flex;
    flex-direction: column; }
    .block.layout .wrap > * {
      padding: 1.5em; }
      @media screen and (min-width: 768px) {
        .block.layout .wrap > * {
          width: 60%; }
          .block.layout .wrap > *.right {
            margin-left: 40%; }
          .block.layout .wrap > *.center {
            margin: 0 auto; }
          .block.layout .wrap > *.full {
            width: 100%; } }
    .block.layout.vert-top {
      justify-content: flex-start; }
    .block.layout.vert-center {
      justify-content: center; }
    .block.layout.vert-bottom {
      justify-content: flex-end; }
  .block.overlay * {
    z-index: 5; }
  .block.overlay-black {
    color: white; }
    .block.overlay-black:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0.2); }
    .block.overlay-black.percent-0:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0); }
    .block.overlay-black.percent-20:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0.2); }
    .block.overlay-black.percent-50:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0.5); }
    .block.overlay-black.percent-80:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0.8); }
    .block.overlay-black.percent-100:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: black; }
  .block.overlay-white {
    color: #212121; }
    .block.overlay-white:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(255, 255, 255, 0.2); }
    .block.overlay-white.percent-0:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(255, 255, 255, 0); }
    .block.overlay-white.percent-20:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(255, 255, 255, 0.2); }
    .block.overlay-white.percent-50:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(255, 255, 255, 0.5); }
    .block.overlay-white.percent-80:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(255, 255, 255, 0.8); }
    .block.overlay-white.percent-100:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: white; }

.image {
  display: block; }
  .image img {
    display: block;
    height: 100%;
    width: 100%; }
  .image.left, .image.right {
    max-width: 45%; }
    .image.left::after, .image.right::after {
      clear: both;
      content: "";
      display: block; }
  .image.left {
    float: left;
    margin: 0 1.5em 1.5em 0; }
  .image.right {
    float: right;
    margin: 0 0 1.5em 1.5em; }

#menu {
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 900;
  width: 100%;
  min-height: 100vh;
  transition: all 150ms ease;
  background-color: #4285f4;
  color: white; }
  #menu nav {
    margin: 0;
    min-height: 100vh;
    padding: 1.5em;
    position: relative;
    background: #4285f4;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch; }
    #menu nav > * {
      flex: 0 1 auto; }
    #menu nav a.logo {
      margin: 0 auto;
      display: block;
      position: relative; }
      #menu nav a.logo > * {
        height: 180px; }
    #menu nav ul {
      font-family: "GoogleSansBold", "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
      font-size: 1.25em; }
      #menu nav ul li {
        margin-bottom: 1.5em;
        display: block; }
        #menu nav ul li a {
          color: white;
          padding: 0.5em 0.75em; }
          #menu nav ul li a:hover, #menu nav ul li a.active {
            color: rgba(255, 255, 255, 0.5); }
          #menu nav ul li a.active {
            font-weight: 700; }
        #menu nav ul li:last-child a {
          background-color: white;
          color: #4285f4; }
          #menu nav ul li:last-child a:hover {
            color: #7baaf7; }
    #menu nav p {
      font-size: 0.875em;
      margin: 0; }

body.nav-active #wrapper {
  overflow: hidden;
  max-height: 100%; }

body.nav-active #menu {
  right: 0; }
  body.nav-active #menu nav {
    min-width: 100%; }

#wrapper > header nav {
  border-top: 6px solid #4285f4;
  top: calc(1.5em + 36px);
  position: relative; }
  #wrapper > header nav ul {
    position: relative;
    text-align: right;
    display: none;
    padding-right: 3em; }
    #wrapper > header nav ul li {
      display: inline-block;
      border-top: 6px solid #ea4335;
      margin-top: -6px;
      margin-right: -4px;
      font-family: "GoogleSansBold", "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif; }
      #wrapper > header nav ul li:nth-child(2) {
        border-color: #fbbc05; }
      #wrapper > header nav ul li:nth-child(3) {
        border-color: #34a853; }
      #wrapper > header nav ul li a {
        padding: 0.5em 1.5em;
        color: #212121;
        display: inline-block; }
        #wrapper > header nav ul li a:hover, #wrapper > header nav ul li a.active {
          color: #4285f4; }
  #wrapper > header nav #menuTrigger {
    background-color: white;
    position: absolute;
    right: 0.75em;
    top: -24px;
    padding: 10px;
    z-index: 1000; }
    #wrapper > header nav #menuTrigger a {
      width: 36px;
      height: 28px;
      transform: rotate(0deg);
      transition: 0.125s ease;
      cursor: pointer;
      display: block;
      position: relative; }
      #wrapper > header nav #menuTrigger a span {
        display: block;
        position: absolute;
        left: 0;
        height: 4px;
        width: 100%;
        background: #ea4335;
        opacity: 1;
        transform: rotate(0deg);
        transition: 0.125s ease; }
        #wrapper > header nav #menuTrigger a span:nth-child(1) {
          top: 0px; }
        #wrapper > header nav #menuTrigger a span:nth-child(2), #wrapper > header nav #menuTrigger a span:nth-child(3) {
          top: 12px;
          background: #fbbc05; }
        #wrapper > header nav #menuTrigger a span:nth-child(4) {
          top: 24px;
          background: #34a853; }
    #wrapper > header nav #menuTrigger.open {
      background-color: transparent; }
      #wrapper > header nav #menuTrigger.open a span:nth-child(1) {
        top: 8px;
        width: 0%;
        left: 50%; }
      #wrapper > header nav #menuTrigger.open a span:nth-child(2), #wrapper > header nav #menuTrigger.open a span:nth-child(3) {
        background: white; }
      #wrapper > header nav #menuTrigger.open a span:nth-child(2) {
        transform: rotate(45deg); }
      #wrapper > header nav #menuTrigger.open a span:nth-child(3) {
        transform: rotate(-45deg); }
      #wrapper > header nav #menuTrigger.open a span:nth-child(4) {
        top: 8px;
        width: 0%;
        left: 50%; }

#wrapper > footer ul#social {
  text-align: center;
  display: block;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch; }
  #wrapper > footer ul#social > * {
    flex: 1 1 auto; }

#pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  border-top: 1px solid #e6e6e6;
  padding-top: 1.5em; }
  #pagination > * {
    flex: 0 1 auto; }
  #pagination > * {
    font-size: 2.5em; }
  #pagination .disabled {
    color: #e6e6e6;
    cursor: default; }

@media screen and (min-width: 768px) {
  #wrapper > header > nav ul {
    display: block; }
  #wrapper > header > nav #menuTrigger {
    display: none; }
  #wrapper > footer ul#social {
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center; }
    #wrapper > footer ul#social > * {
      flex: 0 1 auto; } }

@media screen and (min-width: 1600px) {
  #wrapper > header nav ul {
    padding-right: 9em; } }

.episode {
  border-top: 1px solid #e6e6e6;
  padding: 1.5em 0; }
  .episode .info {
    color: #b7b7b7;
    margin-bottom: 0;
    line-height: 1; }
    .episode .info strong {
      font-size: 1.6em;
      color: #4285f4;
      padding-right: 0.5em; }
  .episode h4 {
    margin-top: 0.5em; }
  .episode .actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 1.5em; }
    .episode .actions > * {
      flex: 0 1 auto; }
    .episode .actions .button.icon {
      background: #b7b7b7;
      font-size: 1em;
      display: inherit; }
      .episode .actions .button.icon:hover {
        background: #fbbc05; }

.bio::after {
  clear: both;
  content: "";
  display: block; }

.bio h2 {
  margin-bottom: 0; }

.bio .info {
  font-family: "GoogleSans", "Roboto", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  color: #4285f4;
  font-size: 1.25em;
  margin-bottom: 1.5em; }
