// episode styles
.episode {
	border-top: $base-border;
	padding: $base-spacing 0;
	.info {
		color: $base-color-light;
		margin-bottom: 0;
		line-height: 1;
		strong {
			font-size: em(24,15);
			color: $brand-color-1;
			padding-right: $tiny-spacing; } }
	h4 {
		margin-top: $tiny-spacing; }
	.actions {
		@include flex-container();
		margin-top: $base-spacing;
		.button.icon {
			background: $base-color-light;
			font-size: em(16);
			display: inherit;
			&:hover {
				background: $brand-color-3; } } } }

